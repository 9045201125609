import React, { useState } from "react";

import { ErrorMessage } from "./field";
import dynamic from "next/dynamic";

const Select = dynamic(() => import('./wrappers/react-select'), {
  ssr: false,
  loading: () => <div className="w-full h-10 border border-pink-500"></div>
});

export const ReactSelectAdapter = React.forwardRef(function ReactSelectAdapter(
  { input, options, meta, icon, label, dark, overrideValue, ...rest },
  ref
) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className={`mb-4 flex flex-row items-center gap-2 ${
            dark ? "text-white" : "text-pink-500"
          }`}
          htmlFor={input.name}
        >
          {icon}
          <span>{label}</span>
        </label>
      )}
      <Select
        {...input}
        id={input.name}
        forwardedRef={ref}
        options={options}
        value={overrideValue || options.find((o) => o.value === input.value)}
        onChange={(props) => {
          input.onChange(props?.value)
        }}
        styles={{
          control: () => {},
          singleValue: (props) => {
            return {...props, visibility: !menuIsOpen ? 'visible' : 'hidden' };
          }
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#DD6ABA",
            primary25: "#DD6ABA40",
            primary50: "#DD6ABA90",
          },
        })}
        className={`react-select ${dark ? "dark-input" : ""} ${(meta.touched && meta.error) ? 'error-input': ''}`}
        classNamePrefix="react-select"
        isClearable
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        {...rest}
      />
      <ErrorMessage>{meta.touched && meta.error}</ErrorMessage>
    </div>
  );
});

export const ReactGroupedSelectAdapter = React.forwardRef(
  function ReactGroupedSelectAdapter({ options, input, ...rest }, ref) {
    return (
      <ReactSelectAdapter
        input={input}
        ref={ref}
        options={options}
        overrideValue={options
          .find((o) => o?.options?.find((o) => o.value === input.value))
          ?.options?.find((o) => o.value === input.value)}
        {...rest}
      />
    );
  }
);
